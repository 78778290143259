import React from 'react';

import './styles.scss';

interface FooterProps {

};

const Footer:React.FC<FooterProps> = ({}:FooterProps) => {
  return (<footer className="footer-wrapper">
    <div className="footer-inner">
      <div className="footer-left">
        <img src="/assets/images/logo-footer.png" alt="" />
        <p>University College London</p>
        <p>Torrington Place</p>
        <p>London WC1E 7HB</p>
      </div>
      <div className="footer-right">
      </div>
      <div className="footer-bottom">
        The MARCH Network was funded by UK Research and Innovation (UKRI) as part of the 2018 Cross-Council Mental Health Plus call&nbsp;<a href="www.mentalhealthresearchmatters.org.uk">www.mentalhealthresearchmatters.org.uk</a>
      </div>
    </div>
  </footer>);
};

export default Footer;