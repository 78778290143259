import React, {useState, useCallback, useRef, useEffect} from 'react';
import {Link} from 'gatsby';
import Hamburger from 'hamburger-react';
import classNames from 'classnames';

import './styles.scss';

export interface NavBarProps {
  slug: string,
  menuItems: {
    label: string,
    linkTo: string,
  }[],
};

const Navbar:React.FC<NavBarProps> = ({menuItems, slug}:NavBarProps) => {
  const [isOpen, setOpen] = useState(classNames(
    'nav-wrapper',
    {
      open: false
    }
  ));

  const handleHamburger = useCallback((toggled) => {
    setOpen(classNames(
      'nav-wrapper',
      {
        open: toggled
      }
    ));
  }, [setOpen]);



  return (<div className={isOpen}>
    <div className="logo-box">
      <Link to="/"><img alt="" src="/assets/images/logo.png" /></Link>
    </div>
    <nav>
      <ul>
      {menuItems.map((menuItem, index) => {
        const menuItemClasses = classNames('menu-item', {
          active: slug===menuItem.linkTo
        });
        return (<li className={menuItemClasses} key={`menuitem-${index}`}>
          <Link to={menuItem.linkTo}>{menuItem.label}</Link>
        </li>)
      })}
      </ul>
    </nav>
    <div className="search-box">
      <input type="text" placeholder="Search" title="search" />
      <button><img src="/assets/images/icon-search.png" alt="search button" /></button>
    </div>
    <Hamburger color="#094B60" onToggle={handleHamburger} />
  </div>);
}

export default Navbar;